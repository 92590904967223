import React from "react";
import Tickets from "../components/Tickets";

const TicketsPage = () => {
  return (
    <div>
      <Tickets />
    </div>
  );
};

export default TicketsPage;
