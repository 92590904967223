import React from "react";
import Gallary from "../components/Gallary";

const GallaryPage = () => {
  return (
    <div>
      <Gallary />
    </div>
  );
};

export default GallaryPage;
