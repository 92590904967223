import React from "react";
import Animals from "../components/Animals";

const AnimalsPage = () => {
  return (
    <div>
      <Animals />
    </div>
  );
};

export default AnimalsPage;
